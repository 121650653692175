<template>
  <!-- 用户收藏信息 -->
  <div class="collect loading">
    <ul class="collect_left my_card">

      <li class="add_btn_Box" @click="isShowInputBox">
        <div class="addbtn_box">
          <span>新增文件夹</span>
          <i class="add_icon el-icon-circle-plus"></i>
        </div>
        <div @click.stop :style="isAddInpuBox? 'top:0px;':'top: -50px;'" class="add_input_box">
          <i @click="onCloseAddInputBox" class="el-icon-circle-close"></i>
          <el-input size="mini" placeholder="请输入文件夹名字" v-model="folderName">
            <template slot="append">
              <div @click="onAddFolder" class="add_btn">新建</div>
            </template>
          </el-input>
        </div>
      </li>

      <li @click="onGetList(item)" v-for="item in folderList" :key="item.id"
          :class="{'is_folder': item.folderName === isFolderName}">
        <div class="folder_name_box">
          <i class="iconfont icon-a-bianzubeifen15"></i>
          <span>{{item.folderName}}</span>
        </div>
        <i v-if="!item.isShow && item.folderName !== '默认收藏夹' " @click.stop="isBtnBox(item)"
           class="el-icon-more"></i>
        <div v-if="item.folderName !== '默认收藏夹'" @click.stop class="btn_box"
             :style="item.isShow? 'right: 0px;':'right: -60px'">
          <el-popover placement="bottom" width="200" v-model="item.visible">
            <p class="edit_title" style="text-align: center; font-size: 14px; margin:20px 0 10px;">
              重命名</p>
            <el-input size="mini" v-model="newInputValue"></el-input>
            <div class=" hand" style="display:flex; margin-top:20px;">
              <span @click="editFolderName(item)" style="width: 50%; text-align: center;">确认</span>
              <span @click="onCancel(item)" class="bl"
                    style="width: 50%; text-align: center;">取消</span>
            </div>
            <i slot="reference" class="iconfont icon-bianji edit_icon" @click="editBtn(item)"></i>
          </el-popover>

          <el-popover placement="bottom" width="200" v-model="item.deleteVisible">
            <p style="margin-top:20px; text-align: center;">确定删除该文件夹吗？</p>
            <div class=" hand" style="display:flex; margin:20px 0 0px 0;">
              <span @click="deleteFolderName(item)"
                    style="width: 50%; text-align: center;">确认</span>
              <span @click="onDeleteCancel(item)" class="bl"
                    style="width: 50%; text-align: center;">取消</span>
            </div>
            <i slot="reference" class="iconfont icon-lajitongicon delete_icon"
               @click="item.visible = false"></i>
          </el-popover>

        </div>
      </li>
    </ul>

    <div class="collect_rigth">
      <div class="folderName_box no_select">
        <div>
          <i class="iconfont icon-a-bianzubeifen15"></i>>>
          {{isFolderName}}
        </div>
        <div class="folderName_btn_box">
          <span class=" hand" v-if="!isRadio" @click="onManage">移动</span>
          <span class="cancel_manage  hand" v-else @click="onCancelManage">取消</span>
        </div>
      </div>
      <ul class="category_list">
        <li @click="categoryChange(item.id)" :class="{'is_categort': item.id === isCategory}"
            v-for="item in categoryList" :key="item.id">{{item.value}}</li>
      </ul>

      <article-list v-if="isCategory === 1" :honDocuList="collectionList" :isRadio="isRadio"
                    @checkboxChange="onCheckboxChange" />

      <GuideList class="mt20" v-if="isCategory === 10" :list="collectionList" :isRadio="isRadio"
                 @checkboxChange="onCheckboxChange" />

      <userCollectVideoList v-if="isCategory===3" :videoList="collectionList" :isRadio="isRadio"
                            @checkboxChange="onCheckboxChange" />

      <div v-if="isRadio" class="select_btn_box my_card">
        <div class="select_left">
          <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange"
                       v-model="isSelectAll">全选</el-checkbox>
          <span class="select_sum">{{isDocListLength}}/{{collectionList.length}}</span>
        </div>
        <div class="select_right">

          <el-dropdown trigger="click" @command="handleCommand">
            <el-button type="primary" size="mini">
              {{isSelectFolderName || '将选中的内容移动至'}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="item.folderName=== isFolderName"
                                :command="item.folderName" v-for="item in folderList"
                                :key="item.id">{{item.folderName}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div @click="onMoveDocument" class="move_btn  hand">
            确定
          </div>
          <div @click="onCancelManage" class="cancel_move_btn   hand">
            取消
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import userCollectVideoList from './userCollectVideoList.vue'
import GuideList from '@/views/guideline/components/list.vue'
import { mapGetters } from 'vuex'
import { getFolderList, editFolderName, deleteFolder, addFolder, getFolderCollectList, changeFolder } from '@/api/user.js'
export default {
  components: {
    userCollectVideoList,
    GuideList
  },
  data () {
    return {
      isDocListLength: 0, // 选中长度
      isSelectFolderName: '', // 移动选择的文件夹名字
      isIndeterminate: false, // 是否有内容
      isSelectAll: false, // 全选框选中状态
      isCategory: 1, // 选中的类别
      // 类别
      categoryList: [
        {
          id: 1,
          value: '文献'
        },
        {
          id: 10,
          value: '指南'
        }, {
          id: 3,
          value: '视频'
        }
      ],
      folderName: '', // 新增文件名
      newInputValue: '', // 修改文件名
      isAddInpuBox: false,
      collectionList: [], // 用户收藏信息
      folderList: [], // 文件夹列表
      // 获取用户收藏信息参数
      obj: {
        category: 1, // 收藏类型
        userId: null // 用户ID
      },
      isFolderName: '默认收藏夹', // 选中文件夹
      isRadio: false // 单选框
    }
  },
  methods: {
    // 移动文献
    async onMoveDocument () {
      try {
        if (!this.isSelectFolderName) return this.$message.warning('请选择移入的文件夹')
        const itemIds = this.collectionList.filter(item => item.radio).map(item => item.id)
        if (itemIds.length === 0) return this.$message.warning('请选择需要移动的文献')
        await changeFolder({
          category: this.isCategory,
          destFolderName: this.isSelectFolderName,
          itemIds
        })
        this.$message.success('文献已移动至:' + this.isSelectFolderName + '文件夹中')
        this.onCancelManage()
        this.getDocnmentList()
      } catch (error) {
        this.$message.error('文献移动失败')
      }
    },
    // 移动文件夹选择事件
    handleCommand (folderName) {
      this.isSelectFolderName = folderName
    },
    // 文献盒子内部暴漏事件
    onCheckboxChange () {
      const isDocList = this.collectionList.filter(item => item.radio)
      this.isDocListLength = isDocList.length
      if (isDocList.length > 0) {
        this.isIndeterminate = true
        this.isSelectAll = false
        if (isDocList.length === this.collectionList.length) {
          this.isIndeterminate = false
          this.isSelectAll = true
        }
      } else {
        this.isIndeterminate = false
        this.isSelectAll = false
      }
    },
    // 全选盒子
    handleCheckAllChange (val) {
      if (val) {
        this.isIndeterminate = false
        this.onSelectAll()
      } else {
        this.onNone()
      }
    },
    // 全不选
    onNone () {
      this.collectionList.forEach(item => { item.radio = false })
      this.isDocListLength = 0
    },
    // 全选
    onSelectAll () {
      this.collectionList.forEach(item => { item.radio = true })
      this.isDocListLength = this.collectionList.length
    },
    // 取消管理按钮
    onCancelManage () {
      this.isRadio = false
      this.isIndeterminate = false
      this.isSelectAll = false
      this.isSelectFolderName = ''
      this.onNone()
    },
    // 管理按钮
    onManage () {
      this.isRadio = true
    },
    // 类别变更
    categoryChange (categoryId) {
      this.onCancelManage()
      this.isCategory = categoryId
      this.getDocnmentList()
    },
    // 新增文件夹
    async onAddFolder () {
      try {
        if (/^\s+$/g.test(this.folderName) || !this.folderName) return this.$message.warning('文件夹名字不可为空')
        if (this.folderList.length >= 10) return this.$message.warning('最多只可以创建10个文件夹')
        if (!/^[a-z0-9\u4e00-\u9fa5]+$/i.test(this.folderName)) return this.$message.warning('只可以输入中/英文和数字')
        if (this.folderName.length > 10) return this.$message.warning('文件夹名字长度不可超过10个字符')
        if (this.folderNameISRepeat(this.folderName)) return this.$message.warning('文件夹名字已存在')
        await addFolder({ folderName: this.folderName })
        this.$message.success('创建成功')
        this.isInput = false
        this.folderName = ''
        this.getFolderList()
      } catch (error) {
        this.$message.error('创建失败')
      }
    },
    // 关闭新增文件盒子
    onCloseAddInputBox () {
      this.isAddInpuBox = false
    },
    // 打开新增文件盒子
    isShowInputBox () {
      this.isAddInpuBox = true
    },
    // 确认删除
    async deleteFolderName (item) {
      try {
        const res = await deleteFolder({ folderName: item.folderName })
        this.$message.success(res.msg)
        this.getFolderList()
      } catch (error) {
        this.$message.error(error)
        item.deleteVisible = false
      }
    },
    // 删除取消
    onDeleteCancel (item) {
      item.deleteVisible = false
    },
    // 编辑文件夹名字
    async editFolderName (item) {
      try {
        if (/^\s+$/g.test(this.newInputValue)) return this.$message.warning('文件夹名字不可为空')
        if (!/^[a-z0-9\u4e00-\u9fa5]+$/i.test(this.newInputValue)) return this.$message.warning('只可以输入中/英文和数字')
        if (this.newInputValue.length > 10) return this.$message.warning('文件夹名字长度不可超过10个字符')
        if (this.folderNameISRepeat(this.newInputValue)) return this.$message.warning('文件夹名字已存在')
        await editFolderName({
          oldFolderName: item.folderName,
          newFolderName: this.newInputValue
        })
        if (item.folderName === this.isFolderName) {
          this.isFolderName = this.newInputValue
        }
        this.$message.success('修改成功')
        this.newInputValue = ''
        this.getFolderList()
      } catch (error) {
        this.$message.error('修改失败')
      }
    },
    // 判断文件名字是否重复
    folderNameISRepeat (name) {
      return this.folderList.find(item => (item.folderName === name))
    },
    // 打开编辑弹窗
    editBtn (item) {
      item.deleteVisible = false
      this.newInputValue = item.folderName
    },
    // 关闭编辑弹窗
    onCancel (item) {
      this.newInputValue = ''
      item.visible = false
    },
    // 文件夹切换
    onGetList (item) {
      if (item.isShow) {
        item.isShow = false
        return
      }
      this.isFolderName = item.folderName
      this.isCategory = 1
      this.onCancelManage()
      this.getDocnmentList()
    },
    // 显示按钮盒子
    isBtnBox (item) {
      this.folderList.forEach(folder => {
        folder.isShow = false
      })
      item.isShow = true
    },
    // 获取文件夹列表
    async getFolderList () {
      const res = await getFolderList()
      this.folderList = res.data.map(item => ({ ...item, isShow: false, visible: false, deleteVisible: false }))
    },
    // 获取用户的收藏信息
    async getDocnmentList () {
      this.collectionList = []
      const res = await getFolderCollectList({
        folderName: this.isFolderName,
        category: this.isCategory
      })
      this.collectionList = res.data.map(item => ({
        ...item,
        isTranslate: false,
        radio: false
      }))
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created () {
    // 获取用户id
    this.obj.userId = this.userInfo.userId

    this.getFolderList()
    this.getDocnmentList()
  }
}
</script>
<style lang="less" scoped>
.collect {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 800px;
  .collect_left {
    position: sticky;
    top: 132px;
    width: 200px;
    min-height: 380px;
    max-height: 600px;
    background-color: #fff;
    padding: 20px 0;
    overflow-y: auto;
    .add_btn_Box {
      .addbtn_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-right: 20px;
        span {
          font-weight: 700;
        }
        .add_icon {
          color: #f1c763;
          font-size: 18px;
        }
      }
    }
    li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      padding-left: 20px;
      cursor: pointer;
      overflow: hidden;
      &::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        content: '';
        width: 160px;
        border-bottom: 1px solid #e8e8e8;
      }
      &:hover {
        background-color: #f1f3f9;
      }
      .icon-a-bianzubeifen15 {
        margin-right: 8px;
        color: #f1c763;
      }
      .el-icon-more {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
      }
      .btn_box {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -60px;
        margin: auto 0;
        height: 50px;
        line-height: 30px;
        width: 60px;
        font-size: 12px;
        background-color: #f1f3f9;
        transition: right 0.3s;
        span {
          width: 50%;
          text-align: center;
        }
      }
      .edit_icon,
      .delete_icon {
        display: inline-block;
        width: 50%;
        text-align: center;
        font-size: 14px;
      }
      .edit_icon {
        border-color: #fcad3a;
        color: #fcad3a;
      }
      .delete_icon {
        border-color: #fc493a;
        color: #fc493a;
      }
      .add_input_box {
        display: flex;
        align-items: center;
        position: absolute;
        top: -50px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transition: top 0.3s;
        padding: 0 20px;
        .el-icon-circle-close {
          position: absolute;
          top: 0px;
          right: 5px;
        }
        /deep/.el-input__inner {
          border-color: #dcdfe6;
        }
        /deep/.el-input__inner:focus {
          border-color: #dcdfe6;
        }
        /deep/.el-input-group__append {
          border-color: #dcdfe6;
          color: #4b639f;
          padding: 0;
          .add_btn {
            width: 100%;
            height: 100%;
            padding: 4px 20px;
            cursor: pointer;
          }
        }
      }
      .folder_name_box {
        display: flex;
      }
    }
    .is_folder {
      background-color: #d7dce9 !important;
    }
  }

  .collect_rigth {
    position: relative;
    width: 1000px;
    min-height: 100%;
    padding-left: 20px;
    .folderName_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;
      color: #4b639f;
      font-size: 16px;
      margin-bottom: 20px;
      .iconfont {
        color: #f1c763;
        margin-right: 5px;
      }
      .folderName_btn_box {
        font-size: 12px;
        display: flex;
        span {
          width: 60px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          border-radius: 17px;
          background-color: #4b639f;
          color: #fff;
          letter-spacing: 3px;
          &:hover {
            opacity: 0.9;
          }
        }
        .cancel_manage {
          background-color: #d7dce9;
          color: #000;
        }
      }
    }
  }
  .category_list {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    li {
      width: 90px;
      height: 100%;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      background-color: #fff;
      margin-right: 30px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: #f1f3f9;
      }
    }
    .is_categort {
      background-color: #d7dce9 !important;
    }
  }
  .select_btn_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    z-index: 99;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    .select_left {
      .select_sum {
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .select_right {
      display: flex;
      align-items: center;
      .el-button--primary {
        min-width: 100px;
        background-color: #4b639f;
        border-color: #4b639f;
      }
      .move_btn,
      .cancel_move_btn {
        margin-left: 20px;
        width: 60px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 17px;
        background-color: #4b639f;
        color: #fff;
        letter-spacing: 3px;
        &:hover {
          opacity: 0.9;
        }
      }
      .cancel_move_btn {
        background-color: #d7dce9;
        color: #000;
      }
    }
  }
}
/deep/.el-checkbox__inner {
  background-color: #f1f3f9;
}
</style>
